import { ApimPostDownloadFile, ApimGet, ApimPost, ApimPut } from "components/Common/ApimSender";
import { isArray } from "../../../tools";
import { format } from "date-fns";

export function StructureGet(structureType, identifiant, callbackFn, errorFn) {
  ApimGet("api/Structure/" + structureType + "/" + identifiant, c => callbackFn(c), e => errorFn(e));
}

export function StructureGetOwnedByWorkflow(workflowId, callbackFn, errorFn) {
  ApimGet("api/Structure/OwnedByWorkflow/" + workflowId, c => callbackFn(c), e => errorFn(e));
}

export function StructureNew(structureType, identifiant, newStructureType, callbackFn, errorFn) {
  ApimGet("api/Structure/" + structureType + "/" + identifiant + "/new/" + newStructureType, c => callbackFn(c), e => errorFn(e));
}

export function StructureCrupdate(model, callbackFn, errorFn, validationErrorFn) {
  if ((model?.structure?.id ?? 0) === 0) {
    ApimPost(
      `api/Structure/${model.structure.structureTypeCode}`,
      model,
      c => callbackFn(c),
      e => errorFn(e),
      e => (e.response.data && isArray(e.response.data) ? validationErrorFn(e.response.data) : errorFn(e))
    );
  } else {
    ApimPut(
      `api/Structure/${model.structure.structureTypeCode}/${model.structure.identifiant}`,
      model,
      c => callbackFn(c),
      e => errorFn(e),
      e => (e.response.data && isArray(e.response.data) ? validationErrorFn(e.response.data) : errorFn(e))
    );
  }
}

export function StructureCrupdateOwnedByWorkflow(model, callbackFn, errorFn, validationErrorFn) {
  ApimPut(
    `api/Structure/OwnedByWorkflow/${model.structure.workflowOwnerId}`,
    model,
    c => callbackFn(c),
    e => errorFn(e),
    e => (e.response.data && isArray(e.response.data) ? validationErrorFn(e.response.data) : errorFn(e))
  );
}

export function StructureCrupdateRelation(relation, callbackFn, errorFn) {
  ApimPost("api/Structure/" + relation.fromType + "/" + relation.toType, relation, c => callbackFn(c), e => errorFn(e));
}

export function GetStructuresWithoutChildren(ids, callbackFn, errorFn) {
  ApimPost("api/Structure/Structures", ids, c => callbackFn(c), e => errorFn(e));
}

export function StructureSearch(criterias, callbackFn, errorFn) {
  return ApimPost("api/Structure/Search", criterias, countries => callbackFn(countries), e => errorFn(e));
}

export function StructureGetTree(schema, dateRef, callbackFn, errorFn) {
  dateRef = dateRef.indexOf("T") > 0 ? dateRef.substr(0, dateRef.indexOf("T")) : dateRef;

  ApimGet("api/Structure/Tree/" + schema + "/" + dateRef, c => callbackFn(c), e => errorFn(e));
}

export function StructureExport(type, elementsToExport, callbackFn, errorFn) {
  var now = format(new Date(), "yyyyMMddHHmmss");
  ApimPostDownloadFile("api/Structure/" + type + "/export", elementsToExport, type + "_" + now + ".csv", callbackFn, errorFn);
}

export function RegroupementExport(ids, callbackFn, errorFn) {
  var now = format(new Date(), "yyyyMMddHHmmss");
  ApimPostDownloadFile("api/Structure/regroupement/export", ids, "Regroupements_" + now + ".csv", callbackFn, errorFn);
}

export async function GetTpCompany(thirdPartyId, callbackFn, errorFn) {
  return await ApimGet("api/Structure/tpCompany/" + thirdPartyId, callbackFn, errorFn);
}

export async function GetTpThirdParty(thirdPartyId, callbackFn, errorFn) {
  return await ApimGet("api/Structure/tpThirdParty/" + thirdPartyId, callbackFn, errorFn);
}

export function GetStructureHistory(structureType, identifiant, callbackFn, errorFn) {
  ApimGet(`api/structure/${structureType}/${identifiant}/histories`, c => callbackFn(c), e => errorFn(e));
}

export function GetStructureHistoryByWkfId(workflowId, callbackFn, errorFn) {
  ApimGet(`api/structure/OwnedByWorkflow/${workflowId}/histories`, c => callbackFn(c), e => errorFn(e));
}

export function GetStructurePath(structureType, identifiant, callbackFn, errorFn) {
  ApimGet(`api/structure/${structureType}/${identifiant}/path`, c => callbackFn(c), e => errorFn(e));
}
