import { ApimGet, ApimPost } from "components/Common/ApimSender";
import { HasOneOfRights } from "services/user/UserHelper";
import * as Actions from "./types";

export function GetBeneficiary(thirdPartyId) {
  return (dispatch, getState) => {
    ApimGet(
      "api/TpCopernic/Beneficiaries/" + thirdPartyId,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_BENEFICIARY_GET,
          beneficiary: responseJson,
          isEditable: HasOneOfRights(["thirdparty_supplier.edit", "thirdparty_customer.edit"]),
          isEditableClosed: HasOneOfRights(["thirdparty_supplier.edit_closed", "thirdparty_customer.edit_closed"])
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_BENEFICIARY_ERROR,
          error: error
        });
      }
    );
  };
}

export function EditBeneficiary(beneficiary) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_BENEFICIARY_EDITVALUE,
      beneficiary: beneficiary
    });
  };
}

function crupdateBeneficiary(beneficiary, reloadThirdParty, dispatch) {
  ApimPost(
    "api/TpCopernic/Beneficiaries",
    beneficiary,
    responseJson => {
      dispatch({
        type: Actions.TP_COPERNIC_BENEFICIARY_SAVE_END,
        successType: reloadThirdParty ? "SUCCESS_RELOAD" : "SUCCESS",
        beneficiary: responseJson
      });
    },
    error => {
      console.error(error);
      dispatch({
        type: Actions.TP_COPERNIC_BENEFICIARY_ERROR,
        error: error
      });
    }
  );
}

export function SaveBeneficiary(beneficiary, thirdParty, reloadThirdParty) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_BENEFICIARY_SAVE_BEGIN
    });

    if (thirdParty?.identifier === 0) {
      ApimPost(
        "api/TpCopernic/ThirdParties",
        thirdParty,
        responseJson => {
          beneficiary.thirdPartyIdentifier = responseJson.identifier;
          crupdateBeneficiary(beneficiary, reloadThirdParty, dispatch);
        },
        error => {
          console.error(error);
          dispatch({
            type: Actions.TP_COPERNIC_CUSTOMER_ERROR,
            error: error
          });
        }
      );
    } else {
      crupdateBeneficiary(beneficiary, reloadThirdParty, dispatch);
    }
  };
}

export function GetSuppliersByBeneficiaryId(beneficiaryId) {
  return (dispatch, getState) => {
    ApimGet(
      "api/TpCopernic/Beneficiaries/" + beneficiaryId + "/suppliers",
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_BENEFICIARY_SUPPLIER_GET,
          suppliers: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_BENEFICIARY_ERROR,
          error: error
        });
      }
    );
  };
}

///---------------------------------------------------
/// Get History
///---------------------------------------------------
export function GetBeneficiaryHistory(thirdPartyId, callbackFn, errorFn) {
  ApimGet("api/tpCopernic/Beneficiaries/" + thirdPartyId + "/histories", callbackFn, errorFn);
}
