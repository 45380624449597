import { Trans } from "@lingui/macro";
import { Button, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Add, Delete, DomainDisabled, Edit, Flag, MoreVert } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import StructureAutocomplete from "components/Autocomplete/StructureAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useState } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import uuidv4 from "uuid/v4"; // Generate GUID
import { isNull } from "../../../tools";
import { GetStructurePath } from "module/structure/actions/StructureActions";

const SiteStructures = function({ isEditable, site, setSite, openStructure }) {
  var [newStructure, setNewStructure] = useState(null);
  var [menuAnchor, setMenuAnchor] = useState(null);
  var [currentStructure, setCurrentStructure] = useState(null);

  if (isNull(site.structures)) {
    return <></>;
  }

  var structures = site.structures.map(s => {
    if (!s.localId) s.localId = uuidv4();
    return s;
  });

  ///------------------------------
  /// Crée le composant graphique pour l'ajout d'un lien
  ///------------------------------
  function showAddStructure() {
    return (
      <div>
        <Card
          style={{
            ...CardInlineStyle.card
          }}
        >
          <CardHeader
            style={{
              ...CardInlineStyle.cardHeader
            }}
            icon
          >
            <h4>
              <Trans>Add link</Trans>
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={11} sm={11} md={11}>
                <StructureAutocomplete
                  isEditable={isEditable}
                  structureTypes={["ERT", "SJ", "UE", "UP"]}
                  value={newStructure}
                  onChange={v => {
                    if (v)
                      var locationStructure = {
                        structureId: v.id,
                        structureDetails: {
                          identifiant: v.identifiant,
                          libelle: v.libelle,
                          structureType: v.structureType
                        },
                        action: "INSERT"
                      };
                    setNewStructure(locationStructure);
                  }}
                  size="small"
                  label={<Trans>Structure_LocationStructure</Trans>}
                />
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <IconButton aria-label="Add" onClick={() => onAddStructure(newStructure)}>
                  <Add />
                </IconButton>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }

  function onAddStructure() {
    if (isNull(newStructure)) return;

    newStructure.action = "INSERT";
    newStructure.localId = uuidv4();
    newStructure.isMainStructure = structures.length === 0;

    GetStructurePath(newStructure.structureDetails.structureType, newStructure.structureDetails.identifiant, c => {
      setSite({ ...site, structures: [...site.structures, { ...newStructure, ...c }] });
      setNewStructure(null);
    });
  }

  function onUpdateMainStructure(localId) {
    setSite({
      ...site,
      structures: site.structures.map(s => {
        if (s.localId === localId) {
          if (!s.isMainStructure) {
            s.isMainStructure = true;
            s.action = s.action ? s.action : "UPDATE";
          }
        } else if (!!s.isMainStructure) {
          s.isMainStructure = false;
          s.action = s.action ? s.action : "UPDATE";
        }

        return s;
      })
    });
  }

  function onDeleteStructure(localId) {
    let setAnotherMainStructure = structures.find(s => s.localId === localId).isMainStructure;

    setSite({
      ...site,
      structures: site.structures
        .map(s => {
          if (s.localId === localId) {
            if (s.action === "INSERT") return null;
            s.isMainStructure = false;
            s.action = "DELETE";
          } else if (setAnotherMainStructure) {
            s.isMainStructure = s.action !== "DELETE";
            s.action = s.action ? s.action : "UPDATE";
            setAnotherMainStructure = false;
          }
          return s;
        })
        .filter(s => s)
    });
  }

  function convertDataToReactTable() {
    if (isNull(structures)) return [];

    return structures.map(structure => {
      var icon = "";
      if (structure.action) {
        var iconStatus = "";
        var iconTitle = "";
        if (structure.action === "INSERT") {
          iconTitle = <Trans>Site_Structure_Insert</Trans>;
          iconStatus = <Add />;
        } else if (structure.action === "UPDATE") {
          iconTitle = <Trans>Site_Structure_Update</Trans>;
          iconStatus = <Edit />;
        } else if (structure.action === "DELETE") {
          iconTitle = <Trans>Site_Structure_Delete</Trans>;
          iconStatus = <Delete />;
        }

        icon = <Tooltip title={iconTitle}>{iconStatus}</Tooltip>;
      }

      return {
        ert: structure.ertIdentifier ? structure.ertIdentifier + " - " + structure.ertLibelle : "",
        ertIdentifier: structure.ertIdentifier,
        sj: structure.sjIdentifier ? structure.sjIdentifier + " - " + structure.sjLibelle : "",
        sjIdentifier: structure.sjIdentifier,
        ue: structure.ueIdentifier ? structure.ueIdentifier + " - " + structure.ueLibelle : "",
        ueIdentifier: structure.ueIdentifier,
        up: structure.upIdentifier ? structure.upIdentifier + " - " + structure.upLibelle : "",
        upIdentifier: structure.upIdentifier,
        isMainStructure: structure.isMainStructure ? <Flag style={{ color: "orange" }} /> : "",
        structureType: structure.structureType,
        identifier: structure.structureIdentifier,
        isClosed: structure.isStructureClosed || structure.computedIsStructureClosed,
        actions: (
          <div className="actions-right">
            {structure.isStructureClosed || structure.computedIsStructureClosed ? (
              <Tooltip title={<Trans>Site_Structure_Closed</Trans>}>
                <DomainDisabled style={{ color: "red" }} />
              </Tooltip>
            ) : (
              ""
            )}
            {isEditable ? (
              <span>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={e => {
                    setCurrentStructure(structure);
                    setMenuAnchor(e.currentTarget);
                  }}
                >
                  <MoreVert />
                </IconButton>
                {icon}
              </span>
            ) : (
              ""
            )}
          </div>
        )
      };
    });
  }

  var showAddStructure = isEditable ? showAddStructure() : "";
  var reactTableColumns = [
    {
      Header: <Trans>Structure_ERT</Trans>,
      accessor: "ert",
      filterable: true,
      Cell: rowInfo => {
        var label = rowInfo.original.ert;
        var isClosed = rowInfo.original.isClosed;
        if (label === "") return <></>;
        return (
          <Tooltip title={label}>
            <Button
              onClick={() => {
                openStructure("ERT", rowInfo.original.ertIdentifier);
              }}
            >
              {isClosed ? <span style={{ color: "red" }}>{label}</span> : <>{label}</>}
            </Button>
          </Tooltip>
        );
      }
    },
    {
      Header: <Trans>Structure_SJ</Trans>,
      accessor: "sj",
      filterable: true,
      Cell: rowInfo => {
        var label = rowInfo.original.sj;
        var isClosed = rowInfo.original.isClosed;
        if (label === "") return <></>;
        return (
          <Tooltip title={label}>
            <Button
              onClick={() => {
                openStructure("SJ", rowInfo.original.sjIdentifier);
              }}
            >
              {isClosed ? <span style={{ color: "red" }}>{label}</span> : <>{label}</>}
            </Button>
          </Tooltip>
        );
      }
    },
    {
      Header: <Trans>Structure_UE</Trans>,
      accessor: "ue",
      filterable: true,
      Cell: rowInfo => {
        var label = rowInfo.original.ue;
        var isClosed = rowInfo.original.isClosed;
        if (label === "") return <></>;
        return (
          <Tooltip title={label}>
            <Button
              onClick={() => {
                openStructure("UE", rowInfo.original.ueIdentifier);
              }}
            >
              {isClosed ? <span style={{ color: "red" }}>{label}</span> : <>{label}</>}
            </Button>
          </Tooltip>
        );
      }
    },
    {
      Header: <Trans>Structure_UP</Trans>,
      accessor: "up",
      filterable: true,
      Cell: rowInfo => {
        var label = rowInfo.original.up;
        var isClosed = rowInfo.original.isClosed;
        if (label === "") return <></>;
        return (
          <Tooltip title={label}>
            <Button
              onClick={() => {
                openStructure("UP", rowInfo.original.upIdentifier);
              }}
            >
              {isClosed ? <span style={{ color: "red" }}>{label}</span> : <>{label}</>}
            </Button>
          </Tooltip>
        );
      }
    },
    {
      Header: <Trans>Site_Structure_Main</Trans>,
      accessor: "isMainStructure",
      filterable: false
    }
  ];
  var menuActions = "";
  if (isEditable) {
    reactTableColumns = [
      {
        Header: <Trans>Actions</Trans>,
        accessor: "actions",
        sortable: false,
        filterable: false
      },
      ...reactTableColumns
    ];
    menuActions = (
      <Menu
        id="menuActions"
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "40ch"
          }
        }}
      >
        <MenuItem
          onClick={() => {
            onUpdateMainStructure(currentStructure.localId);
            setMenuAnchor(null);
          }}
        >
          <Trans>Site_Structure_SetMain</Trans>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteStructure(currentStructure.localId);
            setMenuAnchor(null);
          }}
        >
          <Trans>Site_Structure_Delete</Trans>
        </MenuItem>
      </Menu>
    );
  }

  return (
    <div>
      {showAddStructure}
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>Existing links</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <ReactTable
            data={convertDataToReactTable()}
            columns={reactTableColumns}
            defaultPageSize={5}
            showPaginationBottom={true}
            showPageSizeOptions={false}
            className="-striped -highlight"
            showFilters={true}
            style={ReactTableStyle.main}
          />
        </CardBody>
      </Card>
      {menuActions}
    </div>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    // structureUps: state.MasterValueReducer[StructureUp],
    // structureUes: state.MasterValueReducer[StructureUe],
    // structureSjs: state.MasterValueReducer[StructureSj],
    // structureErts: state.MasterValueReducer[StructureErt]
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(SiteStructures));
