import React, { useEffect, useMemo, useState } from "react";
import { GetUsersByProfileTerritories, HasRight } from "services/user/UserHelper";

import { Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";

import { Trans } from "@lingui/macro";
import Button from "components/CustomButtons/Button";
import DialogBox from "components/DialogBox/DialogBox";
import { WorkflowGetHistories } from "module/workflow/store/actions";
import PaymentCharacteristicLegalDatas from "module/tpCopernic/components/PaymentCharacteristicLegalDatas";
import { ApimGetPromise } from "components/Common/ApimSender";
import { formatDate, sortBy } from "tools";
import { WorkflowStatus } from "store/MasterValue/MasterValueTypes";

function CardValidators({ workflow }) {
  var [state, setState] = useState({ isLoading: false });
  if (!state.isLoading && !state.validators) {
    setState({ isLoading: true });
    GetUsersByProfileTerritories(
      workflow.validatorProfileCodes,
      workflow.validatorTerritoryCountryCode,
      workflow.validatorTerritoryErtId,
      v => setState({ isLoading: false, error: null, validators: v }),
      e => setState({ isLoading: false, error: e, validators: [], histories: [] })
    );
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  var bodyValidators = "";
  if (state.isLoading) {
    bodyValidators = <CircularProgress />;
  } else if (state.validators) {
    bodyValidators = state.validators.map((v, k) => <Chip key={k} label={v.firstName + " " + v.lastName + " (" + v.identifier + ")"} />);
  }

  return (
    <div>
      <strong>
        <Trans>Workflow_WaitingForValidationFrom</Trans>
      </strong>
      {bodyValidators}
    </div>
  );
}

function CardHistories({ workflow, masterValues, defaultLang }) {
  var [state, setState] = useState({ isLoading: false });
  let wkfStatusColor = useMemo(() => {
    switch (workflow?.workflowStatusCode) {
      case "Aborted":
        return "#CC0000";
      case "Finished":
        return "#00CC00";
      default:
        return "#CCCCCC";
    }
  }, [workflow.workflowStatusCode]);

  useEffect(() => {
    setState({ isLoading: true });
    WorkflowGetHistories(
      workflow.identifier,
      h => setState({ isLoading: false, error: null, histories: sortBy(h, "actionDate") }),
      e => setState({ isLoading: false, error: e, histories: [] })
    );
  }, [workflow]);

  if (state.error) {
    return <CardError error={state.error} />;
  }

  var bodyComments = "";
  if (state.isLoading) {
    bodyComments = <CircularProgress />;
  } else if (state.histories && state.histories.length > 0) {
    let lastHistory = state.histories[state.histories.length - 1];
    let comment = lastHistory.actionValues.find(h => h.name === "Comment")?.valueAfter;
    bodyComments = (
      <>
        {lastHistory.actionUser} ({formatDate(lastHistory.actionDate, defaultLang)}) : {comment}
      </>
    );
  }

  return (
    <div>
      <div>
        <strong>
          <Trans>Workflow_Status</Trans>
        </strong>
        &nbsp;
        <Chip
          style={{ backgroundColor: "#FFF", borderStyle: "solid", borderWidth: "1px", borderColor: wkfStatusColor, color: wkfStatusColor }}
          label={masterValues[WorkflowStatus].find(i => i.code === workflow.workflowStatusCode)?.label}
        />
      </div>
      <div>
        <strong>
          <Trans>Workflow_Comment</Trans>
        </strong>
        &nbsp;{bodyComments}
      </div>
    </div>
  );
}

function PayCharCreation({ workflow, validateWorkflow, abortWorkflow, closeWorkflow, masterValues, defaultLang, classes }) {
  const [state, setState] = useState({ isLoading: true });
  const [dialogBox, setDialogBox] = useState(null);

  const loadPayChar = () => {
    setState({ isLoading: true });
    const payCharUrl = `api/TpCopernic/Suppliers/${workflow.workflowPayChar.supplierId}/PaymentCharacteristics/${workflow.workflowPayChar.payCharId}`;
    const supplierUrl = `api/TpCopernic/Suppliers/${workflow.workflowPayChar.supplierId}`;
    const thirdPartyUrl = `api/TpCopernic/ThirdParties/${workflow.workflowPayChar.supplierId}`;
    ApimGetPromise(payCharUrl)
      .then(pc =>
        ApimGetPromise(supplierUrl)
          .then(s =>
            ApimGetPromise(thirdPartyUrl)
              .then(tp => setState({ ...state, isLoading: false, payChar: pc, supplier: s, thirdParty: tp.thirdParty }))
              .catch(e => setState({ isLoading: false, error: e }))
          )
          .catch(e => setState({ isLoading: false, error: e }))
      )
      .catch(e => setState({ isLoading: false, error: e }));
  };

  useEffect(() => {
    loadPayChar();
  }, [workflow]);

  const closeDetail = isFromButton => {
    if (!isFromButton) {
      setDialogBox({ type: "yesNo", message: <Trans> ConfirmCloseWithoutSave </Trans>, yes: () => closeWorkflow() });
    } else {
      closeWorkflow();
    }
  };

  const validate = () => {
    setDialogBox({
      canDismiss: false,
      type: "okCancel",
      textbox: { title: <Trans>Comment</Trans>, rows: 2 },
      ok: text => {
        validateWorkflow({ ...workflow, validatorComment: text });
        setDialogBox(null);
      }
    });
  };

  const abort = () => {
    setDialogBox({
      canDismiss: false,
      type: "okCancel",
      textbox: { title: <Trans>Comment</Trans>, rows: 2 },
      ok: text => {
        abortWorkflow({ ...workflow, validatorComment: text });
        setDialogBox(null);
      }
    });
  };

  let dialogContent;
  if (state.error) dialogContent = <CardError error={state.error} />;
  else if (state.errorNotFound)
    dialogContent = (
      <div>
        <Trans>WF_PayChar_NotFound</Trans>
      </div>
    );
  else if (state.isLoading) dialogContent = <CircularProgress />;
  else if (!state.payChar) dialogContent = <CardError error={{ message: "Associated payment chararacteristic is not found." }} />;
  else
    dialogContent = (
      <PaymentCharacteristicLegalDatas readonly isWfDetail={true} thirdParty={state.thirdParty} supplier={state.supplier} payChar={state.payChar} />
    );

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={Boolean(workflow)}
        onClose={() => closeDetail(false)}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          <div>{workflow.label}</div>
        </DialogTitle>
        <DialogContent
          style={{
            ...DialogInlineStyleDetail.dialogContent
          }}
          dividers={true}
        >
          <Card>
            <CardBody>
              {workflow.workflowStatusCode === "Pending" && <CardValidators workflow={workflow} />}
              <CardHistories workflow={workflow} masterValues={masterValues} defaultLang={defaultLang} />
            </CardBody>
          </Card>
          {dialogContent}
        </DialogContent>
        <DialogActions>
          {workflow.workflowStatusCode === "Pending" && HasRight("thirdparty_workflow.edit_iban") && (
            <Button color="success" onClick={validate}>
              <Trans>Validate</Trans>
            </Button>
          )}
          {workflow.workflowStatusCode === "Pending" && HasRight("thirdparty_workflow.edit_iban") && (
            <Button color="danger" onClick={abort}>
              <Trans>WF_Abort</Trans>
            </Button>
          )}
          <div style={{ flex: "1 0 0" }} />
          <Button onClick={() => closeDetail(true)} color={!state.hasChanged ? "info" : ""}>
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />
    </>
  );
}

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "95%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(PayCharCreation);
