import { Trans } from "@lingui/macro";
import { CircularProgress, Typography } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Add, Assignment } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import * as Actions from "module/workflow/store/actions/Workflow.Actions";
import React, { useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { HasRight } from "services/user/UserHelper";
import { LoadMasterValues, WorkflowStatus, WorkflowType } from "store/MasterValue/MasterValueTypes";
import CardError from "../../../components/Card/CardError";
import WorkflowSearch from "../components/WorkflowSearch";
import { OpenWorkflow } from "../store/actions/Workflow.Actions";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%",
    height: "100%",
    center: "center"
  },
  image: {
    marginTop: "60px",
    marginLeft: "15px",
    position: "relative",
    height: 100,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 50
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15
      },
      "& $imageMarked": {
        opacity: 0
      },
      "& $imageTitle": {
        border: "4px solid currentColor"
      }
    }
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%"
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "#00acc1",
    opacity: 1,
    transition: theme.transitions.create("opacity")
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity")
  }
}));

const WorkflowList = ({ masterValues }) => {
  const [mvIsLoading, setMvIsLoading] = useState("init");
  const classes = useStyles();
  const initialCriteria = {
    workflowStatuses: ["Pending", "Started"]
  };
  var pageSize = useMemo(() => {
    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");
    if (getPref !== null) {
      pageSize = getPref.Pref.ResultPageSize * 1;
    } else {
      pageSize = 10;
    }
  }, []);

  const dispatch = useDispatch();

  const launchSearch = criteria => {
    setState({ ...state, isLoading: true, criteria: criteria });

    Actions.WorkflowSearch(
      criteria,
      c => {
        setState({ ...state, results: c, criteria: criteria, isLoading: false });
      },
      e => {
        setState({ ...state, error: e, criteria: criteria, isLoading: false });
      }
    );
  };

  const [state, setState] = useState({
    criteria: null,
    error: null,
    isLoading: false,
    results: null
  });

  if (mvIsLoading === "init") {
    setMvIsLoading("true");
    LoadMasterValues([WorkflowType, WorkflowStatus]).then(() => {
      setMvIsLoading(null);
      launchSearch(initialCriteria);
    });
  }

  if (!!mvIsLoading) {
    return <CircularProgress />;
  }

  const reactTableColumns = [
    {
      accessor: "actions",
      width: 50,
      sortable: false,
      filterable: false
    },
    {
      Header: <Trans>Id</Trans>,
      accessor: "Identifier"
    },
    {
      Header: <Trans>Title</Trans>,
      accessor: "Title",
      filterMethod: (filter, row) =>
        String(row[filter.id])
          .toLowerCase()
          .includes(filter.value.toLowerCase())
    },
    {
      Header: <Trans>Type</Trans>,
      accessor: "Type"
    },
    {
      Header: <Trans>Status</Trans>,
      accessor: "Status"
    },
    {
      Header: <Trans>Creation User</Trans>,
      accessor: "CreationUser"
    },
    {
      Header: <Trans>Validateur</Trans>,
      accessor: "ValidatorId",
      filterMethod: (filter, row) =>
        String(row[filter.id])
          .toLowerCase()
          .includes(filter.value.toLowerCase())
    },
    {
      Header: <Trans>Creation date</Trans>,
      accessor: "CreationDate"
    }
  ];

  ///-----------------------------------------------------------
  /// Convertit le résultat d'une recherche en item pour react-table
  ///-----------------------------------------------------------
  const ConvertDatas = (workflows, openWorkflow) => {
    var result = {
      pages: workflows.nbOfPages,
      datas: workflows,
      tabItems: []
    };
    workflows.map(prop => {
      result.tabItems.push({
        Identifier: prop.identifier,
        Type: masterValues[WorkflowType].find(mv => mv.code === prop.workflowTypeCode)?.label,
        Title: prop.label,
        Status: masterValues[WorkflowStatus].find(mv => mv.code === prop.workflowStatusCode)?.label,
        ValidatorId: prop.validatorId?.replace(/,/g, ", "),
        CreationDate: prop.creationDate,
        CreationUser: prop.creationUser,
        LastModificationUser: prop.lastModificationUser,
        LastModificationDate: prop.lastModificationDate,
        Operator: prop.address_ZipCode,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                openWorkflow(prop.workflowTypeCode, prop.identifier);
              }}
              color="info"
              className="like"
            >
              <Assignment />
            </Button>{" "}
          </div>
        )
      });
    });

    return result;
  };

  var results = <></>;
  if (state.isLoading) {
    results = (
      <center>
        <CircularProgress />
      </center>
    );
  } else if (state.error) {
    return <CardError error={state.error} />;
  } else if (state.results) {
    var items = ConvertDatas(state.results, (typeCode, id) => dispatch(OpenWorkflow(typeCode, id)));
    var resultTitle = "";
    var reactTable = "";

    reactTable = (
      <ReactTable
        data={items.tabItems}
        columns={reactTableColumns}
        defaultPageSize={pageSize}
        showPaginationBottom={true}
        className="-striped -highlight"
        style={ReactTableStyle.main}
        filterable
      />
    );

    results = (
      <Card>
        <CardHeader icon>
          <div className="blockHeaderResult">
            <div className="blockHeaderResultItem">
              <Typography ariant="display1">{resultTitle}</Typography>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div>{reactTable}</div>
        </CardBody>
      </Card>
    );
  }

  var searchGridSize = 12;
  var buttonWorkflowCreation = "";
  if (HasRight("thirdparty_supplier.request")) {
    searchGridSize = 10;
    buttonWorkflowCreation = (
      <GridItem xs={2} sm={2} md={2}>
        <div className={classes.root}>
          <ButtonBase
            focusRipple
            key={"New Demand"}
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            onClick={() => dispatch(OpenWorkflow("SupplierRequest", 0))}
            style={{
              width: "50%"
            }}
          >
            <span className={classes.imageBackdrop} />
            <span className={classes.imageButton}>
              <Typography component="span" variant="subtitle1" color="inherit" className={classes.imageTitle}>
                <Add />
              </Typography>
            </span>
          </ButtonBase>
        </div>
      </GridItem>
    );
  }

  var layoutSearchResult = (
    <WorkflowSearch
      criteria={state.criteria}
      setCriteria={c => setState({ ...state, criteria: c })}
      launchSearch={() => launchSearch(state.criteria)}
    />
  );

  if (results) {
    layoutSearchResult = (
      <GridContainer align="center" justify="center">
        {buttonWorkflowCreation}
        <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
          {layoutSearchResult}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {results}
        </GridItem>
      </GridContainer>
    );
  }

  return <div>{layoutSearchResult}</div>;
};

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(WorkflowList));
