import * as Actions from "../actions/types";
import { isNull } from "../../../tools";

const initialState = [];

export default function TP_ThirdPartiesReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.TP_COPERNIC_THIRDPARTY_CLOSE:
      return state.filter((item, index) => {
        return index < state.length - 1;
      });

    case Actions.TP_COPERNIC_THIRDPARTY_OPEN:
      return [
        ...state,
        {
          thirdPartyCtx: {
            thirdParty: { identifier: action.thirdPartyIdentifier }
          },
          isLoading: true
        }
      ];

    case Actions.TP_COPERNIC_THIRDPARTY_GET_COMPANY:
      return state.map(s => {
        if (s.thirdPartyCtx.thirdParty.companyIdentifier === action.company.identifier) {
          s.thirdPartyCtx.thirdParty = {
            ...s.thirdPartyCtx.thirdParty,
            company: action.company
          };
        }
        return s;
      });

    case Actions.TP_COPERNIC_THIRDPARTY_RELOAD:
      return state.map(s => {
        if (s.thirdPartyCtx.thirdParty.identifier === action.previousThirdPartyId) {
          return {
            thirdPartyCtx: {
              thirdParty: { identifier: action.thirdPartyIdentifier }
            },
            isLoading: true
          };
        }
        return s;
      });

    case Actions.TP_COPERNIC_THIRDPARTY_EDITVALUE:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            thirdPartyCtx: {
              ...item.thirdPartyCtx,
              thirdParty: action.thirdParty,
              hasChanged: JSON.stringify(action.thirdParty) !== item.thirdPartyCtx.jsonBackup,
              hasChangedMigCode: JSON.stringify(action.thirdParty.migCode) !== item.thirdPartyCtx.jsonBackupMigCode
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_THIRDPARTY_ERROR:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            error: action.error
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_THIRDPARTY_SAVE_BEGIN:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            thirdPartyCtx: {
              ...item.thirdPartyCtx,
              saveStatus: "PENDING",
              error: null
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_THIRDPARTY_SAVE_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            thirdPartyCtx: {
              ...item.thirdPartyCtx,
              thirdParty: {
                ...action.thirdParty,
                warnings: []
              },
              jsonBackup: JSON.stringify(action.thirdParty),
              jsonBackupMigCode: JSON.stringify(action.thirdParty.migCode),
              saveStatus: "SUCCESS_RELOAD",
              hasChanged: false,
              hasChangedMigCode: false
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_THIRDPARTY_SAVE_ERROR:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            thirdPartyCtx: {
              ...item.thirdPartyCtx,
              saveStatus: "ERROR",
              error: action.error
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_THIRDPARTY_READY:
      var payload = action.payload;
      return state.map(s => {
        if (s.thirdPartyCtx.thirdParty.identifier === payload.thirdParty.thirdParty.identifier) {
          s = {
            thirdPartyCtx: {
              thirdParty: {
                ...payload.thirdParty.thirdParty,
                warnings: payload.thirdParty.warnings,
                isPointOfSale: payload.thirdParty.isPointOfSale ?? false,
                migAuthorizeSupplierCreation: payload.thirdParty.migAuthorizeSupplierCreation,
                migIsColas: payload.thirdParty.migIsColas,
                company: payload.company
              },
              jsonBackup: JSON.stringify(payload.thirdParty.thirdParty),
              jsonBackupMigCode: JSON.stringify(payload.thirdParty.thirdParty.migCode),
              isEditable: payload.thirdPartyIsEditable,
              isEditableClosed: payload.thirdPartyIsEditableClosed,
              isLoading: false,
              hasChanged: false,
              hasChangedMigCode: false,
              contacts: isNull(payload.thirdPartyContacts)
                ? []
                : payload.thirdPartyContacts.map(c => {
                    return {
                      contact: c,
                      jsonBackup: JSON.stringify(c),
                      action: "NONE"
                    };
                  }),
              history: payload.thirpartyHistory
            },
            supplierCtx: {
              supplier: payload.supplier,
              supplierSjs: payload.supplierSjs?.map(sj => {
                return { action: "NONE", sj: sj, jsonBackup: JSON.stringify(sj) };
              }),
              paymentCharacteristics: payload.paymentCharacteristics,
              jsonBackup: JSON.stringify(payload.supplier),
              isApplicationAdmin: payload.applicationAdmin,
              isEditable: payload.supplierIsEditable,
              isEditableClosed: payload.supplierIsEditableClosed,
              isEditableCoupa: payload.supplierIsEditableCoupa,
              isEditableDemat: payload.supplierIsEditableDemat,
              isEditableSj: payload.supplierIsEditableSj,
              isEditablePayChar: payload.supplierIsEditablePayChar,
              canAddSj: payload.supplierCanAddSj,
              hasChanged: false,
              history: payload.supplierHistory,
              isEditablePayPeriod: payload.supplierIsEditablePayPeriod
            },
            pointOfSaleCtx: {
              pointOfSale: isNull(payload.pointOfSale?.pointOfSale)
                ? null
                : {
                    ...payload.pointOfSale.pointOfSale,
                    companyIdentifier: payload.pointOfSale.tpCompanyId,
                    companyCustomLongName: payload.pointOfSale.tpCustomCompanyLongName
                  },
              jsonBackup: JSON.stringify(payload.pointOfSale),
              isEditable: payload.supplierIsEditable,
              isEditableClosed: payload.supplierIsEditableClosed,
              isEditableCoupa: payload.supplierIsEditableCoupa,
              isEditableDemat: payload.supplierIsEditableDemat,
              isEditableSj: payload.supplierIsEditableSj,
              canAddSj: payload.supplierCanAddSj,
              hasChanged: false
            },
            accountCtx: {
              account: isNull(payload.account) ? null : payload.account,
              jsonBackup: JSON.stringify(payload.account),
              isEditable: payload.accountIsEditable,
              isEditableClosed: payload.accountIsEditableClosed,
              hasChanged: false
            },
            beneficiaryCtx: {
              beneficiary: isNull(payload.beneficiary) ? null : payload.beneficiary,
              jsonBackup: JSON.stringify(payload.beneficiary),
              isEditable: payload.beneficiaryIsEditable,
              isEditableClosed: payload.beneficiaryIsEditableClosed,
              isEditableSj: payload.beneficiaryIsEditableSj,
              canAddSj: payload.beneficiaryCanAddSj,
              hasChanged: false
            },
            customerCtx: {
              customer: payload.customer,
              customerSjs: payload.customerSjs?.map(sj => {
                return { action: "NONE", sj: sj, jsonBackup: JSON.stringify(sj) };
              }),
              jsonBackup: JSON.stringify(payload.customer),
              isEditable: payload.customerIsEditable,
              isEditableClosed: payload.customerIsEditableClosed,
              isEditableDemat: payload.customerIsEditableDemat,
              isEditableSj: payload.customerIsEditableSj,
              canAddSj: payload.customerCanAddSj,
              hasChanged: false,
              history: payload.customerHistory
            },
            activeTabName: action.activeTabName
          };
        }
        return s;
      });

    /// ==============================================================================
    ///     ACCOUNT
    /// ==============================================================================
    case Actions.TP_COPERNIC_ACCOUNT_EDITVALUE:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            accountCtx: {
              ...item.accountCtx,
              account: action.account,
              hasChanged: JSON.stringify(action.account) !== item.accountCtx.jsonBackup
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_ACCOUNT_GET:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            accountCtx: {
              ...item.accountCtx,
              jsonBackup: JSON.stringify(action.account),
              account: action.account,
              isEditable: action.isEditable,
              isEditableClosed: action.isEditableClosed,
              isEditableSj: action.isEditableSj,
              canAddSj: action.canAddSj,
              hasChanged: false
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_ACCOUNT_ERROR:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            accountCtx: {
              ...item.accountCtx,
              saveStatus: "ERROR",
              error: action.error
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_ACCOUNT_SAVE_BEGIN:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            accountCtx: {
              ...item.accountCtx,
              saveStatus: "PENDING",
              error: null
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_ACCOUNT_SAVE_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            accountCtx: {
              ...item.accountCtx,
              account: action.account,
              jsonBackup: JSON.stringify(action.account),
              hasChanged: false,
              saveStatus: action.successType
            }
          };
        }
        return item;
      });

    /// ==============================================================================
    ///     BENEFICIARY
    /// ==============================================================================
    case Actions.TP_COPERNIC_BENEFICIARY_EDITVALUE:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            beneficiaryCtx: {
              ...item.beneficiaryCtx,
              beneficiary: action.beneficiary,
              hasChanged: JSON.stringify(action.beneficiary) !== item.beneficiaryCtx.jsonBackup
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_BENEFICIARY_GET:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            beneficiaryCtx: {
              ...item.beneficiaryCtx,
              jsonBackup: JSON.stringify(action.beneficiary),
              beneficiary: action.beneficiary,
              isEditable: action.isEditable,
              isEditableClosed: action.isEditableClosed,
              isEditableSj: action.isEditableSj,
              canAddSj: action.canAddSj,
              hasChanged: false
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_BENEFICIARY_ERROR:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            beneficiaryCtx: {
              ...item.beneficiaryCtx,
              saveStatus: "ERROR",
              error: action.error
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_BENEFICIARY_SAVE_BEGIN:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            beneficiaryCtx: {
              ...item.beneficiaryCtx,
              saveStatus: "PENDING",
              error: null
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_BENEFICIARY_SAVE_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            beneficiaryCtx: {
              ...item.beneficiaryCtx,
              beneficiary: action.beneficiary,
              jsonBackup: JSON.stringify(action.beneficiary),
              hasChanged: false,
              saveStatus: action.successType
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_BENEFICIARY_SUPPLIER_GET:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            beneficiaryCtx: {
              ...item.beneficiaryCtx,
              suppliers: action.suppliers
            }
          };
        }
        return item;
      });

    /// ==============================================================================
    ///     CUSTOMER
    /// ==============================================================================
    case Actions.TP_COPERNIC_CUSTOMER_EDITVALUE:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            customerCtx: {
              ...item.customerCtx,
              customer: action.customer,
              hasChanged: JSON.stringify(action.customer) !== item.customerCtx.jsonBackup
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_CUSTOMER_GET:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            customerCtx: {
              ...item.customerCtx,
              jsonBackup: JSON.stringify(action.customer),
              customer: action.customer,
              isEditable: action.isEditable,
              isEditableClosed: action.isEditableClosed,
              isEditableDemat: action.isEditableDemat,
              isEditableSj: action.isEditableSj,
              canAddSj: action.canAddSj,
              hasChanged: false
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_CUSTOMER_ERROR:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            customerCtx: {
              ...item.customerCtx,
              saveStatus: "ERROR",
              error: action.error
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_CUSTOMER_SAVE_BEGIN:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            customerCtx: {
              ...item.customerCtx,
              saveStatus: "PENDING",
              error: null
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_CUSTOMER_SAVE_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            customerCtx: {
              ...item.customerCtx,
              customer: action.customer,
              jsonBackup: JSON.stringify(action.customer),
              hasChanged: false,
              saveStatus: action.successType
            },
            thirdPartyCtx: {
              ...item.thirdPartyCtx,
              thirdParty: {
                ...item.thirdPartyCtx.thirdParty,
                isCustomer: true
              }
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_CUSTOMER_SJ_ADD:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            customerCtx: {
              ...item.customerCtx,
              customerSjs: [
                ...item.customerCtx.customerSjs,
                {
                  jsonBackup: "",
                  sj: action.sj,
                  action: "INSERT"
                }
              ]
            }
          };
        }
      });

    case Actions.TP_COPERNIC_CUSTOMER_SJ_UPD:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            customerCtx: {
              ...item.customerCtx,
              customerSjs: item.customerCtx.customerSjs.map(ctx => {
                var sj = ctx.sj;
                if (sj.sjCode === action.sj.sjCode) {
                  let act = sj.action === "INSERT" ? "INSERT" : ctx.jsonBackup !== JSON.stringify(action.sj) ? "UPDATE" : "NONE";
                  return {
                    ...ctx,
                    sj: action.sj,
                    action: act
                  };
                }
                return ctx;
              })
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_CUSTOMER_SJ_GET:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            customerCtx: {
              ...item.customerCtx,
              customerSjs: action.sjs.map(c => {
                return {
                  sj: c,
                  jsonBackup: JSON.stringify(c),
                  action: "NONE"
                };
              })
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_CUSTOMER_SJ_SAVE_BEGIN:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            customerCtx: {
              ...item.customerCtx,
              saveStatus: "PENDING",
              error: null
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_CUSTOMER_SJ_SAVE_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            customerCtx: {
              ...item.customerCtx,
              saveStatus: "SUCCESS_RELOAD",
              customerSjs: action.sjs.map(c => {
                return {
                  sj: c,
                  jsonBackup: JSON.stringify(c),
                  action: "NONE"
                };
              })
            }
          };
        }
        return item;
      });

    /// ==============================================================================
    ///     POINT OF SALE
    /// ==============================================================================

    case Actions.TP_COPERNIC_POINTOFSALE_EDITVALUE:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            pointOfSaleCtx: {
              ...item.pointOfSaleCtx,
              pointOfSale: action.pointOfSale,
              hasChanged: JSON.stringify(action.pointOfSale) !== item.pointOfSaleCtx.jsonBackup
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_POINTOFSALE_GET:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            pointOfSaleCtx: {
              ...item.pointOfSaleCtx,
              jsonBackup: JSON.stringify(action.pointOfSale),
              pointOfSale: action.pointOfSale,
              isEditable: action.isEditable,
              isEditableClosed: action.isEditableClosed,
              isEditableCoupa: action.isEditableCoupa,
              isEditableDemat: action.isEditableDemat,
              isEditableSj: action.isEditableSj,
              canAddSj: action.canAddSj,
              hasChanged: false
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_POINTOFSALE_SAVE_BEGIN:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            pointOfSaleCtx: {
              ...item.pointOfSaleCtx,
              saveStatus: "PENDING",
              error: null
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_POINTOFSALE_SAVE_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            pointOfSaleCtx: {
              ...item.pointOfSaleCtx,
              pointOfSale: action.pointOfSale,
              jsonBackup: JSON.stringify(action.pointOfSale),
              hasChanged: false,
              saveStatus: action.successType
            },
            thirdPartyCtx: {
              ...item.thirdPartyCtx,
              thirdParty: {
                ...item.thirdPartyCtx.thirdParty,
                isPointOfSale: true
              }
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_POINTOFSALE_ERROR:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            pointOfSaleCtx: {
              ...item.pointOfSaleCtx,
              saveStatus: "ERROR",
              error: action.error
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_POINTOFSALE_COUPA_GET:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            pointOfSaleCtx: {
              ...item.pointOfSaleCtx,
              coupa: action.coupa,
              jsonBackupCoupa: JSON.stringify(action.coupa)
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_POINTOFSALE_COUPA_EDIT:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            pointOfSaleCtx: {
              ...item.pointOfSaleCtx,
              coupa: action.coupa,
              coupaHasChanged: JSON.stringify(action.coupa) !== item.pointOfSaleCtx.jsonBackupCoupa
            }
          };
        }
        return item;
      });

    //case Actions.TP_COPERNIC_POINTOFSALE_COUPA_ERROR:
    //    return state.map((item, index) => {
    //        if (index === state.length - 1) {
    //            return {
    //                ...item,
    //                pointOfSaleCtx: {
    //                    ...item.pointOfSaleCtx,
    //                    coupaError: action.error
    //                }
    //            };
    //        }
    //        return item;
    //    });

    case Actions.TP_COPERNIC_POINTOFSALE_COUPA_GROUP_ADD:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            pointOfSaleCtx: {
              ...item.pointOfSaleCtx,
              coupa: {
                ...item.pointOfSaleCtx.coupa,
                containGroups: [
                  ...item.pointOfSaleCtx.coupa.containGroups,
                  {
                    containGroup: action.containGroup,
                    action: "INSERT"
                  }
                ]
              },
              coupaHasChanged: JSON.stringify(item.pointOfSaleCtx.coupa) !== item.pointOfSaleCtx.jsonBackupCoupa
            }
          };
          return item;
        }
      });

    case Actions.TP_COPERNIC_POINTOFSALE_COUPA_SAVE_BEGIN:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            pointOfSaleCtx: {
              ...item.pointOfSaleCtx,
              saveStatus: "PENDING",
              error: null
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_POINTOFSALE_COUPA_SAVE_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            pointOfSaleCtx: {
              ...item.pointOfSaleCtx,
              saveStatus: "SUCCESS",
              coupa: action.coupa,
              coupaHasChanged: false
            }
          };
        }
        return item;
      });

    /// ==============================================================================
    ///     SUPPLIER
    /// ==============================================================================

    case Actions.TP_COPERNIC_SUPPLIER_EDITVALUE:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              supplier: action.supplier,
              hasChanged: JSON.stringify(action.supplier) !== item.supplierCtx.jsonBackup
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_SUPPLIER_GET:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              supplier: action.supplier,
              jsonBackup: JSON.stringify(action.supplier),
              isEditable: action.isEditable,
              isEditableClosed: action.isEditableClosed,
              isEditableCoupa: action.isEditableCoupa,
              isEditableDemat: action.isEditableDemat,
              isEditableSj: action.isEditableSj,
              isEditablePayChar: payload.isEditablePayChar,
              canAddSj: action.canAddSj,
              hasChanged: false,
              isEditablePayPeriod: action.isEditablePayPeriod
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_SUPPLIER_ERROR:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              saveStatus: "ERROR",
              error: action.error
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_SUPPLIER_PAYCHAR_GET:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              paymentCharacteristics: action.paymentCharacteristics
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_SUPPLIER_PAYCHAR_UPD:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              paymentCharacteristics: action.payChars
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_SUPPLIER_PAYCHAR_SAVE_BEGIN:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              saveStatus: "PENDING",
              coupaError: null,
              error: null
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_SUPPLIER_PAYCHAR_SAVE_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              saveStatus: "SUCCESS_RELOAD",
              paymentCharacteristics: action.payChars
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_SUPPLIER_PAYCHAR_ERR:
    case Actions.TP_COPERNIC_SUPPLIER_POINTOFSALES_ERR:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              error: action.error
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_PAYMENT_CHAR_SIS_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              paymentCharacteristics: item.supplierCtx.paymentCharacteristics.map(pcItem => {
                // replace old item.paymentCharacteristic by new if ID are same
                if (pcItem.identifier === action.paymentCharacteristicId) {
                  return action.paymentCharacteristic;
                }

                // leave every other item unchanged
                return pcItem;
              })
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_SUPPLIER_POINTOFSALES_GET:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              pointOfSales: action.pointOfSales
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_SUPPLIER_SAVE_BEGIN:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              saveStatus: "PENDING",
              error: null
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_SUPPLIER_SAVE_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              supplier: action.supplier,
              jsonBackup: JSON.stringify(action.supplier),
              saveStatus: action.successType,
              hasChanged: false
            },
            thirdPartyCtx: {
              ...item.thirdPartyCtx,
              thirdParty: {
                ...item.thirdPartyCtx.thirdParty,
                isSupplier: true
              }
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_SUPPLIER_COUPA_GET:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              coupa: action.coupa,
              jsonBackupCoupa: JSON.stringify(action.coupa)
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_SUPPLIER_COUPA_EDIT:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              coupa: action.coupa,
              coupaHasChanged: JSON.stringify(action.coupa) !== item.supplierCtx.jsonBackupCoupa
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_SUPPLIER_COUPA_GROUP_ADD:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              coupa: {
                ...item.supplierCtx.coupa,
                containGroups: [
                  ...item.supplierCtx.coupa.containGroups,
                  {
                    containGroup: action.containGroup,
                    action: "INSERT"
                  }
                ]
              },
              coupaHasChanged: JSON.stringify(item.supplierCtx.coupa) !== item.supplierCtx.jsonBackupCoupa
            }
          };
          return item;
        }
      });

    case Actions.TP_COPERNIC_SUPPLIER_COUPA_SAVE_BEGIN:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              saveStatus: "PENDING",
              coupaError: null,
              error: null
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_SUPPLIER_COUPA_SAVE_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              saveStatus: "SUCCESS",
              coupa: action.coupa,
              coupaHasChanged: false
            }
          };
        }
        return item;
      });

    //case Actions.TP_COPERNIC_SUPPLIER_COUPA_ERROR:
    //    return state.map((item, index) => {
    //        if (index === state.length - 1) {
    //            return {
    //                ...item,
    //                supplierCtx: {
    //                    ...item.supplierCtx,
    //                    saveStatus: "ERROR",
    //                    coupaError: action.error
    //                }
    //            };
    //        }
    //        return item;
    //    });

    case Actions.TP_COPERNIC_SUPPLIER_SJ_ADD:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              supplierSjs: [
                ...item.supplierCtx.supplierSjs,
                {
                  jsonBackup: "",
                  sj: action.sj,
                  action: "INSERT"
                }
              ]
            }
          };
        }
      });

    case Actions.TP_COPERNIC_SUPPLIER_SJ_UPD:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              supplierSjs: item.supplierCtx.supplierSjs.map(ctx => {
                var sj = ctx.sj;
                if (sj.sjCode === action.sj.sjCode) {
                  let act = sj.action === "INSERT" ? "INSERT" : ctx.jsonBackup !== JSON.stringify(action.sj) ? "UPDATE" : "NONE";
                  return {
                    ...ctx,
                    sj: action.sj,
                    action: act
                  };
                }
                return ctx;
              })
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_SUPPLIER_SJ_GET:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              sjs: action.sjs.map(c => {
                return {
                  sj: c,
                  jsonBackup: JSON.stringify(c),
                  action: "NONE"
                };
              })
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_SUPPLIER_SJ_SAVE_BEGIN:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              saveStatus: "PENDING",
              error: null
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_SUPPLIER_SJ_SAVE_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            supplierCtx: {
              ...item.supplierCtx,
              saveStatus: "SUCCESS_RELOAD",
              sjs: action.sjs.map(c => {
                return {
                  sj: c,
                  jsonBackup: JSON.stringify(c),
                  action: "NONE"
                };
              })
            }
          };
        }
        return item;
      });

    /// ==============================================================================
    ///     THIRD PARTY CONTACT
    /// ==============================================================================

    case Actions.TP_COPERNIC_THIRDPARTY_CONTACT_ADD:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            thirdPartyCtx: {
              ...item.thirdPartyCtx,
              contacts: [
                ...item.thirdPartyCtx.contacts,
                {
                  jsonBackup: "",
                  contact: action.contact,
                  action: "INSERT"
                }
              ]
            }
          };
          return item;
        }
      });
    case Actions.TP_COPERNIC_THIRDPARTY_CONTACT_REM:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            thirdPartyCtx: {
              ...item.thirdPartyCtx,
              contacts: item.thirdPartyCtx.contacts
                .map(ctx => {
                  var contact = ctx.contact;
                  if (contact.id === action.contactId) {
                    if (action.isNewContact) return null;
                    return {
                      ...ctx,
                      action: "DELETE"
                    };
                  }
                  return ctx;
                })
                .filter(ctx => ctx !== null)
            }
          };
        }
        return item;
      });
    case Actions.TP_COPERNIC_THIRDPARTY_CONTACT_UPD:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            thirdPartyCtx: {
              ...item.thirdPartyCtx,
              contacts: item.thirdPartyCtx.contacts.map(ctx => {
                var contact = ctx.contact;
                if (contact.id === action.contact.id) {
                  return {
                    ...ctx,
                    contact: action.contact,
                    action: contact.action === "INSERT" ? "INSERT" : ctx.jsonBackup !== JSON.stringify(action.contact) ? "UPDATE" : "NONE"
                  };
                }
                return ctx;
              })
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_THIRDPARTY_CONTACT_GET:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            thirdPartyCtx: {
              ...item.thirdPartyCtx,
              contacts: action.contacts.map(c => {
                return {
                  contact: c,
                  jsonBackup: JSON.stringify(c),
                  action: "NONE"
                };
              })
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_THIRDPARTY_CONTACT_SAVE_BEGIN:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            thirdPartyCtx: {
              ...item.thirdPartyCtx,
              saveStatus: "PENDING",
              error: null
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_THIRDPARTY_CONTACT_SAVE_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            thirdPartyCtx: {
              ...item.thirdPartyCtx,
              saveStatus: "SUCCESS",
              contacts: action.contacts.map(c => {
                return {
                  contact: c,
                  jsonBackup: JSON.stringify(c),
                  action: "NONE"
                };
              })
            }
          };
        }
        return item;
      });

    default:
      return state;
  }
}
