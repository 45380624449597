import { IconButton, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Lock, LockOpen } from "@material-ui/icons";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { useEffect, useState } from "react";
import thirdPartySearchCriteriaStyle from "../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import { isArrayNullOrEmpty, isNull } from "../../tools";
import GetMasterValueLabel from "../Common/MasterValueLabel";
import Muted from "../Typography/Muted";

function ItemAutoComplete({
  isEditable,
  isRequired,
  items,
  selectedValue,
  onValueChange,
  label,
  placeHolder,
  prefixCode,
  formatText,
  multiple,
  error,
  size,
  groupBy,
  sortByText,
  maxResultNumber,
  disabled,
  forceLock,
  onForceLockChanged,
  readOnly
}) {
  var [value, setValue] = useState(!!multiple ? [] : null);
  if (!formatText) {
    formatText = i => (prefixCode ? `(${i.code}) ${GetMasterValueLabel(i)}` : GetMasterValueLabel(i));
  }
  var [options, setOptions] = useState([]);

  useEffect(() => {
    let lstOptions = items
      .map(i => {
        let value = {
          value: i.code,
          text: formatText(i)
        };

        if (groupBy) {
          value = { ...value, group: groupBy(i) };
        }

        return value;
      })
      .sort((a, b) => {
        if (groupBy) {
          if (sortByText) {
            if (a.group.localeCompare(b.group) === 0) return a.text.localeCompare(b.text);
            else return a.group.localeCompare(b.group);
          } else return a.group.localeCompare(b.group);
        } else if (sortByText) {
          return a.text.localeCompare(b.text);
        }
        return 0;
      });
    setOptions(lstOptions);

    if (selectedValue && (!multiple || !isArrayNullOrEmpty(selectedValue))) {
      setValue(!!multiple ? lstOptions.filter(c => selectedValue.some(v => v === c.value)) : lstOptions.find(c => c.value === selectedValue));
    } else if (isNull(selectedValue)) {
      setValue(!!multiple ? [] : null);
    }
  }, [items]);

  const useStyles = makeStyles(theme =>
    size === "small"
      ? {
          inputRoot: {
            fontSize: "14px"
          }
        }
      : {}
  );
  const styles = {
    roundLabel: {
      borderRadius: "16px",
      background: "#e0e0e0",
      padding: "6px 6px",
      fontSize: "14px",
      justifyContent: "center",
      boxSizing: "border-box",
      border: "none",
      height: "32px",
      alignItems: "center",
      color: "#000000de"
    }
  };
  const classes = useStyles(size);

  if (isEditable) {
    const defaultFilterOptions = createFilterOptions();

    return (
      <Autocomplete
        classes={classes}
        clearOnEscape={!isRequired}
        disableClearable={isRequired}
        disabled={!!disabled}
        multiple={!!multiple}
        options={options}
        getOptionLabel={option => option.text}
        onChange={(event, newValue) => {
          setValue(newValue);
          onValueChange(
            newValue
              ? !!multiple
                ? newValue.map(v => {
                    return v.value;
                  })
                : newValue.value
              : null
          );
        }}
        groupBy={groupBy ? v => v.group : null}
        filterOptions={maxResultNumber ? (o, s) => defaultFilterOptions(o, s).slice(0, maxResultNumber) : (o, s) => defaultFilterOptions(o, s)}
        value={value}
        renderInput={params => {
          return isNull(forceLock) ? (
            <TextField
              {...params}
              margin={size === "small" ? "none" : "normal"}
              fullWidth
              error={error}
              label={!!label ? label : null}
              placeholder={placeHolder}
            />
          ) : (
            <TextField
              {...params}
              margin={size === "small" ? "none" : "normal"}
              fullWidth
              error={error}
              label={!!label ? label : null}
              placeholder={placeHolder}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={onForceLockChanged} onMouseDown={e => e.preventDefault()}>
                    {forceLock ? <Lock /> : <LockOpen />}
                  </IconButton>
                </InputAdornment>
              }
            />
          );
        }}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.text, inputValue);
          const parts = parse(option.text, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    );
  }

  if (!selectedValue || (multiple && isArrayNullOrEmpty(selectedValue))) {
    return <Typography>Ø</Typography>;
  }

  if (multiple) {
    var item = items.filter(c => selectedValue.some(s => s === c.code));
    if (isArrayNullOrEmpty(item)) {
      return <Typography>Ø</Typography>;
    }

    return (
      <>
        {item.map((i, idx) =>
          readOnly ? (
            <div style={{ margin: "9px" }}>
              <span key={idx} style={styles.roundLabel}>
                {formatText(i)}
              </span>
            </div>
          ) : (
            <span key={idx} style={styles.roundLabel}>
              {formatText(i)}
            </span>
          )
        )}
      </>
    );
  } else {
    var item = items.find(c => c.code === selectedValue);
    if (!item) {
      return <Typography>Ø</Typography>;
    }

    return <Muted>{formatText(item)}</Muted>;
  }
}

export default withStyles(thirdPartySearchCriteriaStyle)(ItemAutoComplete);
